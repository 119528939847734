export const levelAvailability = {
  1: [1, 2, 3],
  2: [1, 2, 3],
  3: [1, 2, 3],
  4: [1, 2, 3],
  5: [1, 2, 3],
  6: [1, 2, 3],
  7: [1, 2, 3],
  8: [1, 2, 3],
  9: [1, 2, 4, 5],
  10: [1, 2, 6, 7],
  11: [1, 2, 8, 9],
  12: [1, 2, 10, 11],
  13: [1, 2, 12, 13],
  14: [1, 2, 14, 15],
  15: [1, 2, 16, 17],
  16: [1, 2, 18, 19],
  17: [1, 2, 20, 21],
  18: [1, 2, 4, 5],
  19: [1, 2, 6, 7],
  20: [1, 2, 8, 9],
  21: [1, 2, 10, 11],
  22: [1, 2, 12, 13],
  23: [1, 2, 14, 15],
  24: [1, 2, 16, 17],
  25: [1, 2, 18, 19],
  26: [1, 2, 20, 21],
  27: [1, 2, 4, 5],
  28: [1, 2, 6, 7],
  29: [1, 2, 8, 9],
  30: [1, 2, 10, 11],
  31: [1, 2, 12, 13],
  32: [1, 2, 14, 15],
  33: [1, 2, 16, 17],
  34: [1, 2, 18, 19],
  35: [1, 2, 20, 21],
  36: [1, 2, 4, 5],
  37: [1, 2, 6, 7],
  38: [1, 2, 8, 9],
  39: [1, 2, 10, 11],
  40: [1, 2, 12, 13],
  41: [1, 2, 14, 15],
  42: [1, 2, 16, 17],
  43: [1, 2, 18, 19],
  44: [1, 2, 3, 4],
  45: [1, 4, 5, 6],
  46: [1, 4, 7, 8],
  47: [1, 10, 11],
  48: [1, 4, 5, 6],
  49: [1, 7, 8, 9],
  50: [1, 10, 11, 12],
  51: [1, 2, 3],
  52: [1, 4, 5, 6],
};
export const weeklyContests = {
  8: "game/1?mode=5&&quest=dquest4",
  9: "game/1?mode=5&&quest=dquest4",
  10: "game/1?mode=5&&quest=dquest4",
  11: "game/2?mode=5&&quest=dquest2",
  12: "game/1?mode=5&&quest=dquest5",
  42: "game/1?mode=5&&quest=dquest1",
  43: "game/1?mode=5&&quest=dquest2",
  44: "game/1?mode=5&&quest=dquest2",
  45: "game/2?mode=4",
  46: "game/1?mode=5&&quest=dquest4",
  47: "game/1?mode=5&&quest=dquest5",
  48: "game/1?mode=5&&quest=dquest1",
  49: "game/1?mode=5&&quest=dquest1",
  50: "game/1?mode=5&&quest=dquest1",
  51: "game/1?mode=5&&quest=dquest1",
  52: "game/1?mode=5&&quest=dquest1",
};
