export const level1Data = {
  quests: [
    {
      quest: "dquest1",
      type: 1,
      image: "level-1",
      positions: [
        { top: { x: 352, y: 1138 }, bottom: { x: 446, y: 1320 } },
        { top: { x: 1317, y: 190 }, bottom: { x: 1373, y: 255 } },
      ],
    },
    {
      quest: "dquest2",
      type: 1,
      image: "level-1-dup1",
      positions: [
        { top: { x: 349, y: 831 }, bottom: { x: 403, y: 876 } },
        { top: { x: 1394, y: 1100 }, bottom: { x: 1472, y: 1157 } },
      ],
    },
    {
      quest: "dquest3",
      type: 1,
      image: "level-1-dup2",
      positions: [
        { top: { x: 1348, y: 571 }, bottom: { x: 1412, y: 647 } },
        { top: { x: 824, y: 801 }, bottom: { x: 912, y: 903 } },
      ],
    },
    {
      quest: "dquest4",
      type: 1,
      image: "level-1-dup3",
      positions: [
        { top: { x: 743, y: 1230 }, bottom: { x: 820, y: 1353 } },
        { top: { x: 402, y: 602 }, bottom: { x: 461, y: 676 } },
      ],
    },
    {
      quest: "dquest5",
      type: 1,
      image: "level-1-dup4",
      positions: [
        { top: { x: 711, y: 634 }, bottom: { x: 767, y: 696 } },
        { top: { x: 1336, y: 1300 }, bottom: { x: 1436, y: 1353 } },
      ],
    },
    {
      quest: "quest1",
      positions: [
        { top: { x: 352, y: 1138 }, bottom: { x: 446, y: 1320 } },
        { top: { x: 1317, y: 190 }, bottom: { x: 1373, y: 255 } },
      ],
    },
    {
      quest: "quest2",
      description: "Find Evee",
      positions: [{ top: { x: 530, y: 521 }, bottom: { x: 589, y: 580 } }],
    },

    {
      quest: "quest3",
      description: "Find Snubbull",
      positions: [{ top: { x: 1017, y: 1142 }, bottom: { x: 1075, y: 1211 } }],
    },
    {
      quest: "quest4",
      description: "Find Snubbull",
      positions: [{ top: { x: 785, y: 792 }, bottom: { x: 840, y: 842 } }],
    },
    {
      quest: "quest5",
      description: "Find all starter Pokemons",
      positions: [
        { top: { x: 1150, y: 620 }, bottom: { x: 1253, y: 689 } },
        { top: { x: 875, y: 858 }, bottom: { x: 969, y: 963 } },
        { top: { x: 946, y: 789 }, bottom: { x: 1000, y: 865 } },
        { top: { x: 1344, y: 1303 }, bottom: { x: 1430, y: 1355 } },
      ],
    },
    {
      quest: "quest6",
      description: "Find Furret",
      positions: [{ top: { x: 412, y: 635 }, bottom: { x: 480, y: 687 } }],
    },
    {
      quest: "quest7",
      description: "Find Pinsir",
      positions: [{ top: { x: 0, y: 420 }, bottom: { x: 38, y: 483 } }],
    },
    {
      quest: "quest8",
      description: "Find Moltress",
      positions: [{ top: { x: 994, y: 1 }, bottom: { x: 1102, y: 94 } }],
    },
    {
      quest: "quest9",
      description: "Find Mareep",
      positions: [{ top: { x: 1113, y: 1165 }, bottom: { x: 1207, y: 1238 } }],
    },
    {
      quest: "quest10",
      description: "Find Grimer",
      positions: [{ top: { x: 568, y: 731 }, bottom: { x: 635, y: 783 } }],
    },
  ],
  translationSpace: "pokemon",
  name: "Pokemon Fair",
  level: 1,
  catalogOrder: 7,
  updateDate: new Date(),
};
export const level2Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Hayao Miyazaki",
      positions: [{ top: { x: 991, y: 859 }, bottom: { x: 1024, y: 949 } }],
    },
    {
      quest: "quest2",
      description: "Find Baron",

      positions: [{ top: { x: 1048, y: 1234 }, bottom: { x: 1086, y: 1284 } }],
    },
    {
      quest: "quest3",
      description: "Find Isao Takahata",
      positions: [{ top: { x: 955, y: 857 }, bottom: { x: 987, y: 965 } }],
    },
    {
      quest: "quest4",
      description: "Find Dora",
      hint: "",
      positions: [{ top: { x: 628, y: 94 }, bottom: { x: 663, y: 127 } }],
    },
    {
      quest: "quest5",
      description: "Find Curtis",
      hint: "",
      positions: [{ top: { x: 936, y: 1131 }, bottom: { x: 979, y: 1244 } }],
    },
    {
      quest: "quest6",
      description: "Find Nonoko",
      hint: "",
      positions: [{ top: { x: 590, y: 1092 }, bottom: { x: 615, y: 1121 } }],
    },
    {
      quest: "quest7",
      description: "Find Boh",
      hint: "",
      positions: [{ top: { x: 905, y: 1018 }, bottom: { x: 924, y: 1042 } }],
    },
    {
      quest: "quest8",
      description: "Find all characters of Whisper of the Heart",
      hint: "",
      positions: [
        { top: { x: 810, y: 941 }, bottom: { x: 888, y: 1028 } },
        { top: { x: 1048, y: 1238 }, bottom: { x: 1085, y: 1290 } },
        { top: { x: 1427, y: 691 }, bottom: { x: 1453, y: 764 } },
      ],
    },
    {
      quest: "quest9",
      description: "Find Howl",
      hint: "He is transformed",
      positions: [{ top: { x: 1616, y: 58 }, bottom: { x: 1696, y: 150 } }],
    },
    {
      quest: "quest10",
      description: "Find all the Totoros",
      positions: [
        { top: { x: 1330, y: 434 }, bottom: { x: 1414, y: 557 } },
        { top: { x: 1154, y: 814 }, bottom: { x: 1216, y: 872 } },
      ],
    },
    {
      quest: "quest11",
      description: "Find Lili",
      hint: "It is Jiji's girlfriend(Kiki)",
      positions: [{ top: { x: 930, y: 660 }, bottom: { x: 960, y: 690 } }],
    },
    {
      quest: "quest12",
      description: "Find Tombo",
      hint: "looks like Waldo (Kiki)",
      positions: [{ top: { x: 622, y: 1084 }, bottom: { x: 857, y: 1170 } }],
    },
    {
      quest: "quest13",
      description: "Find Kushana",
      hint: "princess from Nausicaa",
      positions: [{ top: { x: 782, y: 885 }, bottom: { x: 809, y: 950 } }],
    },
    {
      quest: "quest14",
      description: "Find Nausicaa",
      hint: "flying",
      positions: [{ top: { x: 588, y: 542 }, bottom: { x: 636, y: 646 } }],
    },
    {
      quest: "quest15",
      description: "Find Asbel",
      hint: "prince from Nausicaa",
      positions: [{ top: { x: 603, y: 962 }, bottom: { x: 662, y: 1017 } }],
    },
    {
      quest: "quest16",
      description: "Find Ponyo",
      hint: "surfing",
      positions: [{ top: { x: 1048, y: 96 }, bottom: { x: 1092, y: 146 } }],
    },
    {
      quest: "quest17",
      description: "Find Sosuke",
      hint: "driving",
      positions: [{ top: { x: 1112, y: 76 }, bottom: { x: 1147, y: 207 } }],
    },
    {
      quest: "quest18",
      description: "Find Ashitaka",
      hint: "from Mononoke",
      positions: [{ top: { x: 745, y: 170 }, bottom: { x: 785, y: 250 } }],
    },
    {
      quest: "quest19",
      description: "Find Kiki",
      hint: "",
      positions: [{ top: { x: 1143, y: 1030 }, bottom: { x: 1181, y: 1120 } }],
    },
    {
      quest: "quest20",
      description: "Find Ursula",
      hint: "from Kiki",
      positions: [{ top: { x: 1607, y: 910 }, bottom: { x: 1666, y: 995 } }],
    },
    {
      quest: "quest21",
      description: "Find the princess Kaguya",
      hint: "",
      positions: [{ top: { x: 965, y: 411 }, bottom: { x: 1005, y: 483 } }],
    },
    {
      quest: "quest22",
      description: "Find San",
      hint: "",
      positions: [{ top: { x: 847, y: 89 }, bottom: { x: 902, y: 216 } }],
    },
    {
      quest: "dquest1",
      type: 1,
      image: "level-2-dup1",
      positions: [
        { top: { x: 1043, y: 225 }, bottom: { x: 1093, y: 311 } },
        { top: { x: 1338, y: 990 }, bottom: { x: 1374, y: 1055 } },
      ],
    },
    {
      quest: "dquest2",
      type: 1,
      image: "level-2-dup2",
      positions: [
        { top: { x: 1048, y: 96 }, bottom: { x: 1097, y: 143 } },
        { top: { x: 820, y: 1045 }, bottom: { x: 856, y: 1099 } },
      ],
    },
  ],
  translationSpace: "ghibli",
  name: "Ghibli",
  level: 2,
  catalogOrder: 1,
  updateDate: new Date(),
};
export const level3Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Sora",
      hint: "from Kingdom Hearts",
      positions: [{ top: { x: 1344, y: 1230 }, bottom: { x: 1388, y: 1278 } }],
    },
    {
      quest: "quest2",
      description: "Find the Highwind",
      hint: "FF7 airship",
      positions: [{ top: { x: 523, y: 223 }, bottom: { x: 626, y: 275 } }],
    },
    {
      quest: "quest3",
      description: "Find Cloud",
      hint: "big blade",
      positions: [{ top: { x: 712, y: 998 }, bottom: { x: 740, y: 1042 } }],
    },
    {
      quest: "quest4",
      description: "Find Ashley (Vagrant Story)",
      positions: [{ top: { x: 928, y: 998 }, bottom: { x: 959, y: 1042 } }],
    },
    {
      quest: "quest5",
      description: "Find Terra (Final Fantasy)",
      hint: "on her mecha",
      positions: [{ top: { x: 1130, y: 673 }, bottom: { x: 1195, y: 742 } }],
    },
    {
      quest: "quest6",
      description: "Find Bombo (Final Fantasy)",
      hint: "auto destructive",
      positions: [{ top: { x: 1295, y: 1213 }, bottom: { x: 1322, y: 1249 } }],
    },
    {
      quest: "quest7",
      description: "Find Justin (Grandia)",
      hint: "at the end of the world",
      positions: [{ top: { x: 1787, y: 1154 }, bottom: { x: 1819, y: 1195 } }],
    },
    {
      quest: "quest8",
      description: "Find 2B, 9s (Nier)",
      hint: "near the bridge",
      positions: [{ top: { x: 275, y: 1120 }, bottom: { x: 308, y: 1168 } }],
    },
    {
      quest: "quest9",
      description: "Find Vivi (Final Fantasy)",
      hint: "black mage",
      positions: [{ top: { x: 386, y: 916 }, bottom: { x: 410, y: 950 } }],
    },
    {
      quest: "quest10",
      description: "Find Flamy (Legend of Mana)",
      hint: "a dragon",
      positions: [{ top: { x: 439, y: 92 }, bottom: { x: 523, y: 188 } }],
    },
    {
      quest: "quest11",
      description: "Find Nina (Breath of Fire)",
      hint: "next to Ryu",
      positions: [{ top: { x: 1496, y: 1226 }, bottom: { x: 1511, y: 1261 } }],
    },
    {
      quest: "quest12",
      description: "Find the Behemot",
      hint: "from Final Fantasy",
      positions: [{ top: { x: 1512, y: 1123 }, bottom: { x: 1620, y: 1194 } }],
    },
    {
      quest: "quest13",
      description: "Find the 2 Valkyries",
      hint: "from Odin Sphere and Valkyrie Profile",
      positions: [
        { top: { x: 810, y: 349 }, bottom: { x: 834, y: 425 } },
        { top: { x: 790, y: 438 }, bottom: { x: 850, y: 498 } },
      ],
    },
    {
      quest: "quest14",
      description: "Find the Prima Vista",
      hint: "airship from Final Fantasy IX",
      positions: [{ top: { x: 1490, y: 272 }, bottom: { x: 1583, y: 365 } }],
    },
    {
      quest: "quest15",
      description: "Find Odin",
      hint: "from Odin Sphere",
      positions: [{ top: { x: 1109, y: 983 }, bottom: { x: 1187, y: 1060 } }],
    },
    {
      quest: "quest16",
      description: "Find the Slime",
      hint: "from Dragon Quest",
      positions: [{ top: { x: 1000, y: 809 }, bottom: { x: 1080, y: 880 } }],
    },
    {
      quest: "quest17",
      description: "Find Emil",
      hint: "from Nier",
      positions: [{ top: { x: 1174, y: 716 }, bottom: { x: 1238, y: 776 } }],
    },
    {
      quest: "quest18",
      description: "Find the Minstrel",
      hint: "from Romancing Saga",
      positions: [{ top: { x: 903, y: 876 }, bottom: { x: 934, y: 916 } }],
    },
    {
      quest: "quest19",
      description: "Find characters from Chrono Trigger",
      hint: "Chrono, Frog, Robot",
      positions: [
        { top: { x: 1260, y: 800 }, bottom: { x: 1289, y: 841 } },
        { top: { x: 903, y: 1069 }, bottom: { x: 961, y: 1114 } },
        { top: { x: 958, y: 1050 }, bottom: { x: 990, y: 1078 } },
      ],
    },
    {
      quest: "quest20",
      description: "Find the Malboro",
      hint: "from Final Fantasy",
      positions: [{ top: { x: 220, y: 780 }, bottom: { x: 272, y: 829 } }],
    },
    {
      quest: "quest21",
      description: "Find the Moogle",
      hint: "from Final Fantasy",
      positions: [{ top: { x: 747, y: 1167 }, bottom: { x: 769, y: 1194 } }],
    },
    {
      quest: "quest22",
      description: "Find Torneko",
      hint: "from Dragon Quest fishing",
      positions: [{ top: { x: 1293, y: 802 }, bottom: { x: 1331, y: 845 } }],
    },
    {
      quest: "quest23",
      description: "Find the Triforce",
      hint: "from Zelda",
      positions: [{ top: { x: 565, y: 930 }, bottom: { x: 629, y: 985 } }],
    },
    {
      quest: "dquest1",
      type: 1,
      image: "level-3-dup1",
      positions: [
        { top: { x: 507, y: 954 }, bottom: { x: 532, y: 974 } },
        { top: { x: 997, y: 811 }, bottom: { x: 1073, y: 881 } },
      ],
    },
  ],
  translationSpace: "jrpg",
  name: "J-RPG",
  level: 3,
  catalogOrder: 4,
  updateDate: new Date(),
};
export const level4Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Link",
      hint: "He is green, not the wolf !",
      positions: [{ top: { x: 1107, y: 775 }, bottom: { x: 1144, y: 846 } }],
    },
    {
      quest: "quest2",
      description: "Find Niko",
      hint: "from Wind Waker",
      positions: [{ top: { x: 1278, y: 929 }, bottom: { x: 1319, y: 992 } }],
    },
    {
      quest: "quest3",
      description: "Find Malon",
      hint: "Keeper of Epona",
      positions: [{ top: { x: 1488, y: 485 }, bottom: { x: 1520, y: 543 } }],
    },
    {
      quest: "quest4",
      description: "Find King of Red Lions",
      hint: "from Wind Waker",
      positions: [{ top: { x: 330, y: 677 }, bottom: { x: 426, y: 1063 } }],
    },
    {
      quest: "quest5",
      description: "Find 4 Zelda princesses",
      hint: "From Wind waker, Breath of the Wild, Ocarina of Time and Spirit Tracks",
      positions: [
        { top: { x: 144, y: 1138 }, bottom: { x: 178, y: 1196 } },
        { top: { x: 460, y: 533 }, bottom: { x: 508, y: 600 } },
        { top: { x: 1357, y: 205 }, bottom: { x: 1393, y: 266 } },
        { top: { x: 833, y: 90 }, bottom: { x: 904, y: 180 } },
      ],
    },
    {
      quest: "quest6",
      description: "Find Medli",
      hint: "Priestress from Wind Waker",
      positions: [{ top: { x: 648, y: 456 }, bottom: { x: 695, y: 526 } }],
    },
    {
      quest: "quest7",
      description: "Find the Wind Baton",
      hint: "from Wind Waker",
      positions: [{ top: { x: 1618, y: 439 }, bottom: { x: 1651, y: 493 } }],
    },
    {
      quest: "quest8",
      description: "Find the Twilight Princess",
      hint: "It is Midna",
      positions: [{ top: { x: 1683, y: 420 }, bottom: { x: 1716, y: 462 } }],
    },
    {
      quest: "quest9",
      description: "Find Saria",
      hint: "from Ocarina of Time",
      positions: [{ top: { x: 541, y: 297 }, bottom: { x: 583, y: 360 } }],
    },
    {
      quest: "quest10",
      description: "Find Old Impa",
      hint: "from Skyward Sword",
      positions: [{ top: { x: 1271, y: 146 }, bottom: { x: 1337, y: 230 } }],
    },
    {
      quest: "quest11",
      description: "Find the Wind Fish",
      hint: "from Links Awakening",
      positions: [{ top: { x: 1339, y: 265 }, bottom: { x: 1483, y: 341 } }],
    },
    {
      quest: "quest12",
      description: "Find Cap Ezlo",
      hint: "from Links Awakening",
      positions: [{ top: { x: 1146, y: 153 }, bottom: { x: 1168, y: 190 } }],
    },
    {
      quest: "quest13",
      description: "Find Groose",
      hint: "from Skyward Sword",
      positions: [{ top: { x: 770, y: 223 }, bottom: { x: 823, y: 287 } }],
    },
    {
      quest: "quest14",
      description: "Find Valoo",
      hint: "from Wind Waker",
      positions: [{ top: { x: 247, y: 49 }, bottom: { x: 781, y: 147 } }],
    },
    {
      quest: "quest15",
      description: "Find Revali",
      hint: "from Breath of the Wild",
      positions: [{ top: { x: 512, y: 22 }, bottom: { x: 677, y: 115 } }],
    },
    {
      quest: "quest16",
      description: "Find the Zora Princess",
      hint: "from Breath of the Wild",
      positions: [{ top: { x: 1142, y: 266 }, bottom: { x: 1177, y: 328 } }],
    },
    {
      quest: "quest17",
      description: "Find Tingle",
      hint: "from Wind Waker",
      positions: [{ top: { x: 1342, y: 433 }, bottom: { x: 1384, y: 490 } }],
    },
    {
      quest: "quest18",
      description: "Find the 2 Korok",
      hint: "from Wind Waker",
      positions: [
        { top: { x: 748, y: 290 }, bottom: { x: 780, y: 321 } },
        { top: { x: 1355, y: 783 }, bottom: { x: 1406, y: 846 } },
      ],
    },
    {
      quest: "quest19",
      description: "Find Epona",
      hint: "from Ocarina",
      positions: [{ top: { x: 1151, y: 755 }, bottom: { x: 1238, y: 850 } }],
    },
    {
      quest: "quest20",
      description: "Find different Gaepora",
      hint: "wise man",
      positions: [
        { top: { x: 655, y: 321 }, bottom: { x: 693, y: 418 } },
        { top: { x: 1181, y: 1088 }, bottom: { x: 1223, y: 1125 } },
      ],
    },
    {
      quest: "quest21",
      description: "Find Skull Kid",
      hint: "Majoras Mask",
      positions: [{ top: { x: 1175, y: 196 }, bottom: { x: 1211, y: 251 } }],
    },
    {
      quest: "quest22",
      description: "Find different Ganondorf",
      hint: "vilain",
      positions: [
        { top: { x: 721, y: 380 }, bottom: { x: 895, y: 505 } },
        { top: { x: 1718, y: 563 }, bottom: { x: 1768, y: 640 } },
      ],
    },
    {
      quest: "dquest1",
      type: 1,
      image: "level-4-dup1",
      positions: [
        { top: { x: 1338, y: 429 }, bottom: { x: 1397, y: 490 } },
        { top: { x: 1123, y: 1096 }, bottom: { x: 1154, y: 1154 } },
      ],
    },
  ],
  translationSpace: "zelda",
  name: "ZELDA",
  level: 4,
  catalogOrder: 3,
  updateDate: new Date(),
};
export const level5Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Bomberman",
      hint: "",
      positions: [{ top: { x: 961, y: 392 }, bottom: { x: 1003, y: 445 } }],
    },
    {
      quest: "quest2",
      description: "Find Doom",
      hint: "First FPS from 1993",
      positions: [{ top: { x: 1069, y: 423 }, bottom: { x: 1101, y: 494 } }],
    },
    {
      quest: "quest3",
      description: "Find Freddy",
      hint: "from Queen",
      positions: [{ top: { x: 1287, y: 256 }, bottom: { x: 1347, y: 409 } }],
    },
    {
      quest: "quest4",
      description: "Find Flurby",
      hint: "the toy",
      positions: [{ top: { x: 634, y: 734 }, bottom: { x: 696, y: 803 } }],
    },
    {
      quest: "quest5",
      description: "Find Rocky",
      hint: "The boxer",
      positions: [{ top: { x: 1595, y: 403 }, bottom: { x: 1649, y: 527 } }],
    },
    {
      quest: "quest6",
      description: "Find the Terminator",
      hint: "",
      positions: [{ top: { x: 351, y: 577 }, bottom: { x: 385, y: 669 } }],
    },
    {
      quest: "quest7",
      description: "Find Indiana Jones",
      hint: "",
      positions: [{ top: { x: 858, y: 403 }, bottom: { x: 912, y: 513 } }],
    },
    {
      quest: "quest8",
      description: "Find Turtle in Times Arcade booth",
      hint: "",
      positions: [{ top: { x: 1230, y: 549 }, bottom: { x: 1282, y: 644 } }],
    },
    {
      quest: "quest9",
      description: "Find a Tamagochi",
      hint: "from Ocarina of Time",
      positions: [{ top: { x: 1816, y: 229 }, bottom: { x: 1857, y: 263 } }],
    },
    {
      quest: "quest10",
      description: "Find Chop Chop Master",
      hint: "from Skyward Sword",
      positions: [{ top: { x: 800, y: 1032 }, bottom: { x: 845, y: 1140 } }],
    },
    {
      quest: "quest11",
      description: "Find Mario",
      hint: "from Links Awakening",
      positions: [{ top: { x: 1111, y: 498 }, bottom: { x: 1138, y: 570 } }],
    },
    {
      quest: "quest12",
      description: "Find Sonic",
      hint: "from Links Awakening",
      positions: [{ top: { x: 326, y: 948 }, bottom: { x: 374, y: 1025 } }],
    },
    {
      quest: "quest13",
      description: "Find Optimus Prime",
      hint: "from Skyward Sword",
      positions: [{ top: { x: 1172, y: 442 }, bottom: { x: 1275, y: 1124 } }],
    },
    {
      quest: "quest14",
      description: "Find Falcor",
      hint: "from Wind Waker",
      positions: [{ top: { x: 0, y: 166 }, bottom: { x: 351, y: 527 } }],
    },
    {
      quest: "quest15",
      description: "Find Willow",
      hint: "from Breath of the Wild",
      positions: [{ top: { x: 51, y: 965 }, bottom: { x: 85, y: 1021 } }],
    },
    {
      quest: "quest16",
      description: "Find Dark Vador ",
      hint: "from Breath of the Wild",
      positions: [{ top: { x: 1038, y: 1166 }, bottom: { x: 1102, y: 1266 } }],
    },
    {
      quest: "quest17",
      description: "Find Marty",
      hint: "from Wind Waker",
      positions: [{ top: { x: 1684, y: 478 }, bottom: { x: 1742, y: 594 } }],
    },
    {
      quest: "quest18",
      description: "Find Pacman",
      hint: "from Wind Waker",
      positions: [{ top: { x: 200, y: 622 }, bottom: { x: 226, y: 658 } }],
    },
    {
      quest: "quest19",
      description: "Find Dorothée",
      hint: "on a sofa",
      positions: [{ top: { x: 1074, y: 652 }, bottom: { x: 1134, y: 730 } }],
    },
  ],
  translationSpace: "nineties",
  name: "90s",
  level: 5,
  catalogOrder: 20,
  updateDate: new Date(),
};

export const level6Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Princess Knight",
      hint: "",
      positions: [{ top: { x: 1385, y: 405 }, bottom: { x: 1538, y: 637 } }],
    },
    {
      quest: "quest2",
      description: "Find Blackjack",
      hint: "",
      positions: [{ top: { x: 1101, y: 296 }, bottom: { x: 1227, y: 546 } }],
    },
    {
      quest: "quest3",
      description: "Find Bouddha",
      hint: "from Queen",
      positions: [{ top: { x: 274, y: 1080 }, bottom: { x: 347, y: 1178 } }],
    },
    {
      quest: "quest4",
      description: "Find Unico",
      hint: "the toy",
      positions: [{ top: { x: 1080, y: 561 }, bottom: { x: 1153, y: 625 } }],
    },
    {
      quest: "quest5",
      description: "Find Tamasaburo",
      hint: "The boxer",
      positions: [{ top: { x: 973, y: 15 }, bottom: { x: 1022, y: 70 } }],
    },
    {
      quest: "quest6",
      description: "Find Dororo",
      hint: "",
      positions: [{ top: { x: 1709, y: 399 }, bottom: { x: 1831, y: 534 } }],
    },
    {
      quest: "quest7",
      description: "Find Mustache",
      hint: "",
      positions: [{ top: { x: 1642, y: 903 }, bottom: { x: 1733, y: 1068 } }],
    },
    {
      quest: "quest8",
      description: "Find Saruta",
      hint: "",
      positions: [{ top: { x: 1190, y: 1211 }, bottom: { x: 1297, y: 1333 } }],
    },
    {
      quest: "quest9",
      description: "Find Phenix",
      hint: "",
      positions: [{ top: { x: 1388, y: 103 }, bottom: { x: 1755, y: 384 } }],
    },
    {
      quest: "quest10",
      description: "Find Atom",
      hint: "",
      positions: [{ top: { x: 341, y: 276 }, bottom: { x: 506, y: 439 } }],
    },
    {
      quest: "quest11",
      description: "Find Melmo",
      hint: "",
      positions: [{ top: { x: 1297, y: 18 }, bottom: { x: 1379, y: 192 } }],
    },
    {
      quest: "quest12",
      description: "Find Rock",
      hint: "",
      positions: [{ top: { x: 436, y: 900 }, bottom: { x: 531, y: 1037 } }],
    },
    {
      quest: "quest13",
      description: "Find Sharaku",
      hint: "",
      positions: [{ top: { x: 772, y: 30 }, bottom: { x: 863, y: 177 } }],
    },
    {
      quest: "quest14",
      description: "Find Adolf",
      hint: "",
      positions: [{ top: { x: 1791, y: 33 }, bottom: { x: 1858, y: 213 } }],
    },
    {
      quest: "quest15",
      description: "Find Prince Norman",
      hint: "",
      positions: [{ top: { x: 1043, y: 9 }, bottom: { x: 1123, y: 192 } }],
    },
    {
      quest: "quest16",
      description: "Find Manjiro",
      hint: "",
      positions: [{ top: { x: 1315, y: 448 }, bottom: { x: 1394, y: 613 } }],
    },
    {
      quest: "quest17",
      description: "Find Barbara",
      hint: "",
      positions: [{ top: { x: 167, y: 1120 }, bottom: { x: 235, y: 1217 } }],
    },
    {
      quest: "quest18",
      description: "Find Rainbow Parakeet",
      hint: "",
      positions: [{ top: { x: 1510, y: 851 }, bottom: { x: 1559, y: 1059 } }],
    },
  ],
  translationSpace: "tezuka",
  name: "TEZUKA",
  level: 6,
  catalogOrder: 19,
  updateDate: new Date(),
};

export const level7Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Arlong",
      hint: "",
      positions: [{ top: { x: 528, y: 534 }, bottom: { x: 726, y: 748 } }],
    },
    {
      quest: "quest2",
      description: "Find Bentham",
      hint: "",
      positions: [{ top: { x: 14, y: 448 }, bottom: { x: 158, y: 604 } }],
    },
    {
      quest: "quest3",
      description: "Find Marco",
      hint: "from Queen",
      positions: [{ top: { x: 290, y: 316 }, bottom: { x: 352, y: 494 } }],
    },
    {
      quest: "quest4",
      description: "Find Rob Lucci",
      hint: "the toy",
      positions: [{ top: { x: 698, y: 570 }, bottom: { x: 854, y: 760 } }],
    },
    {
      quest: "quest5",
      description: "Find Law",
      hint: "The boxer",
      positions: [{ top: { x: 74, y: 632 }, bottom: { x: 194, y: 766 } }],
    },
    {
      quest: "quest6",
      description: "Find Charlos",
      hint: "",
      positions: [{ top: { x: 1154, y: 1106 }, bottom: { x: 1320, y: 1304 } }],
    },
    {
      quest: "quest7",
      description: "Find Charlotte Linlin",
      hint: "",
      positions: [{ top: { x: 654, y: 312 }, bottom: { x: 810, y: 558 } }],
    },
    {
      quest: "quest8",
      description: "Find Buggy",
      hint: "",
      positions: [{ top: { x: 604, y: 982 }, bottom: { x: 710, y: 1144 } }],
    },
    {
      quest: "quest9",
      description: "Find Crocodile",
      hint: "",
      positions: [{ top: { x: 406, y: 1028 }, bottom: { x: 588, y: 1336 } }],
    },
    {
      quest: "quest10",
      description: "Find Gecko Moria",
      hint: "",
      positions: [{ top: { x: 1308, y: 1028 }, bottom: { x: 1430, y: 1208 } }],
    },
    {
      quest: "quest11",
      description: "Find Enel",
      hint: "",
      positions: [{ top: { x: 86, y: 776 }, bottom: { x: 408, y: 978 } }],
    },
  ],
  translationSpace: "onepiece",
  name: "ONE PIECE",
  level: 7,
  catalogOrder: 5,
  updateDate: new Date(),
};

export const level8Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Gara",
      hint: "",
      positions: [{ top: { x: 1234, y: 1164 }, bottom: { x: 1371, y: 1351 } }],
    },
    {
      quest: "quest2",
      description: "Find Ten ten",
      hint: "",
      positions: [{ top: { x: 420, y: 1088 }, bottom: { x: 555, y: 1269 } }],
    },
    {
      quest: "quest3",
      description: "Find Jiraya",
      hint: "from Queen",
      positions: [{ top: { x: 300, y: 587 }, bottom: { x: 474, y: 757 } }],
    },
    {
      quest: "quest4",
      description: "Find Neji",
      hint: "the toy",
      positions: [{ top: { x: 1018, y: 986 }, bottom: { x: 1219, y: 1175 } }],
    },
    {
      quest: "quest5",
      description: "Find Obito",
      hint: "The boxer",
      positions: [{ top: { x: 191, y: 740 }, bottom: { x: 282, y: 853 } }],
    },
    {
      quest: "quest6",
      description: "Find Chiyo",
      hint: "",
      positions: [{ top: { x: 698, y: 653 }, bottom: { x: 779, y: 792 } }],
    },
    {
      quest: "quest7",
      description: "Find Konan",
      hint: "",
      positions: [{ top: { x: 452, y: 407 }, bottom: { x: 559, y: 609 } }],
    },
    {
      quest: "quest8",
      description: "Find Shikamaru",
      hint: "",
      positions: [{ top: { x: 1480, y: 1071 }, bottom: { x: 1593, y: 1286 } }],
    },
    {
      quest: "quest9",
      description: "Find Karin",
      hint: "",
      positions: [{ top: { x: 1539, y: 454 }, bottom: { x: 1630, y: 557 } }],
    },
    {
      quest: "quest10",
      description: "Find Onoki",
      hint: "",
      positions: [{ top: { x: 657, y: 315 }, bottom: { x: 805, y: 461 } }],
    },
    {
      quest: "quest11",
      description: "Find Team 7 members",
      hint: "",
      positions: [
        { top: { x: 957, y: 489 }, bottom: { x: 1079, y: 707 } },
        { top: { x: 1103, y: 635 }, bottom: { x: 1234, y: 909 } },
        { top: { x: 1567, y: 1040 }, bottom: { x: 1637, y: 1223 } },
        { top: { x: 1674, y: 876 }, bottom: { x: 1767, y: 1044 } },
      ],
    },
  ],
  translationSpace: "naruto",
  name: "NARUTO",
  level: 8,
  catalogOrder: 12,
  updateDate: new Date(),
};

export const level9Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Sazabi",
      hint: "",
      positions: [{ top: { x: 696, y: 227 }, bottom: { x: 936, y: 654 } }],
    },
    {
      quest: "quest2",
      description: "Find The Witch from Mercury",
      hint: "",
      positions: [{ top: { x: 886, y: 734 }, bottom: { x: 1033, y: 892 } }],
    },
    {
      quest: "quest3",
      description: "Find a Space colony",
      hint: "",
      positions: [{ top: { x: 742, y: 923 }, bottom: { x: 1055, y: 1106 } }],
    },
    {
      quest: "quest4",
      description: "Find Magelan type spaceship",
      hint: "",
      positions: [{ top: { x: 61, y: 864 }, bottom: { x: 747, y: 1349 } }],
    },
    {
      quest: "quest5",
      description: "Find Asteroid Base Axis",
      hint: "",
      positions: [{ top: { x: 1448, y: 249 }, bottom: { x: 1587, y: 335 } }],
    },
    {
      quest: "quest6",
      description: "Find a Zaku",
      hint: "",
      positions: [{ top: { x: 1593, y: 439 }, bottom: { x: 1801, y: 661 } }],
    },
    {
      quest: "quest7",
      description: "Find Big Zam",
      hint: "",
      positions: [{ top: { x: 586, y: 15 }, bottom: { x: 817, y: 246 } }],
    },
    {
      quest: "quest8",
      description: "Find Lala Sune",
      hint: "",
      positions: [{ top: { x: 1320, y: 321 }, bottom: { x: 1421, y: 379 } }],
    },
    {
      quest: "quest9",
      description: "Find Antogonist of Gundam Zeta",
      hint: "",
      positions: [{ top: { x: 557, y: 562 }, bottom: { x: 764, y: 798 } }],
    },
    {
      quest: "quest10",
      description: "Find Seebook and Cecily",
      hint: "",
      positions: [{ top: { x: 1666, y: 288 }, bottom: { x: 1798, y: 361 } }],
    },
  ],
  translationSpace: "gundam",
  name: "GUNDAM",
  level: 9,
  catalogOrder: 17,
  updateDate: new Date(),
};

export const level10Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Liquid Snake",
      hint: "",
      positions: [{ top: { x: 806, y: 425 }, bottom: { x: 866, y: 590 } }],
    },
    {
      quest: "quest2",
      description: "Find Solidus Snake ",
      hint: "",
      positions: [{ top: { x: 714, y: 837 }, bottom: { x: 793, y: 992 } }],
    },
    {
      quest: "quest3",
      description: "Find Ninja",
      hint: "",
      positions: [{ top: { x: 1249, y: 152 }, bottom: { x: 1369, y: 264 } }],
    },
    {
      quest: "quest4",
      description: "Find Meryl",
      hint: "",
      positions: [{ top: { x: 1102, y: 919 }, bottom: { x: 1164, y: 1022 } }],
    },
    {
      quest: "quest5",
      description: "Find Skull Face",
      hint: "",
      positions: [{ top: { x: 987, y: 445 }, bottom: { x: 1038, y: 529 } }],
    },
    {
      quest: "quest6",
      description: "Find Ocelot",
      hint: "",
      positions: [{ top: { x: 1441, y: 1111 }, bottom: { x: 1541, y: 1280 } }],
    },
    {
      quest: "quest7",
      description: "Find Mist Unit",
      hint: "",
      positions: [{ top: { x: 1203, y: 789 }, bottom: { x: 1309, y: 879 } }],
    },
    {
      quest: "quest8",
      description: "Find Raiden",
      hint: "",
      positions: [{ top: { x: 1556, y: 853 }, bottom: { x: 1657, y: 974 } }],
    },
    {
      quest: "quest9",
      description: "Find Miller",
      hint: "",
      positions: [{ top: { x: 531, y: 540 }, bottom: { x: 584, y: 690 } }],
    },
    {
      quest: "quest10",
      description: "Find Sunny",
      hint: "",
      positions: [{ top: { x: 599, y: 518 }, bottom: { x: 643, y: 632 } }],
    },
    {
      quest: "quest11",
      description: "Find Quiet",
      hint: "",
      positions: [{ top: { x: 1717, y: 88 }, bottom: { x: 1757, y: 160 } }],
    },
  ],
  translationSpace: "metalgear",
  name: "METAL GEAR SOLID",
  level: 10,
  catalogOrder: 18,
  updateDate: new Date(),
};

export const level11Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Cooler",
      hint: "",
      positions: [{ top: { x: 1358, y: 510 }, bottom: { x: 1550, y: 685 } }],
    },
    {
      quest: "quest2",
      description: "Find Ginyu",
      hint: "",
      positions: [{ top: { x: 192, y: 58 }, bottom: { x: 394, y: 281 } }],
    },
    {
      quest: "quest3",
      description: "Find Bojack",
      hint: "",
      positions: [{ top: { x: 61, y: 891 }, bottom: { x: 150, y: 1004 } }],
    },
    {
      quest: "quest4",
      description: "Find Android 14",
      hint: "",
      positions: [{ top: { x: 1790, y: 240 }, bottom: { x: 1865, y: 394 } }],
    },
    {
      quest: "quest5",
      description: "Find Garlic Jr",
      hint: "",
      positions: [{ top: { x: 1323, y: 1015 }, bottom: { x: 1378, y: 1056 } }],
    },
    {
      quest: "quest6",
      description: "Find Janemba",
      hint: "",
      positions: [{ top: { x: 1594, y: 802 }, bottom: { x: 1786, y: 953 } }],
    },
    {
      quest: "quest7",
      description: "Find Master Kaio",
      hint: "",
      positions: [{ top: { x: 1409, y: 932 }, bottom: { x: 1543, y: 1087 } }],
    },
    {
      quest: "quest8",
      description: "Find Dabura",
      hint: "",
      positions: [{ top: { x: 1680, y: 6 }, bottom: { x: 1820, y: 137 } }],
    },
    {
      quest: "quest9",
      description: "Find Dr Gero",
      hint: "",
      positions: [{ top: { x: 113, y: 188 }, bottom: { x: 157, y: 240 } }],
    },
    {
      quest: "quest10",
      description: "Find grandpa Gohan",
      hint: "",
      positions: [{ top: { x: 696, y: 1231 }, bottom: { x: 751, y: 1275 } }],
    },
    {
      quest: "quest11",
      description: "Find Chaozu",
      hint: "",
      positions: [{ top: { x: 342, y: 761 }, bottom: { x: 404, y: 829 } }],
    },
  ],
  translationSpace: "dbz",
  name: "DRAGON BALL Z",
  level: 11,
  catalogOrder: 16,
  updateDate: new Date(),
};

export const level12Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Sailor Team",
      hint: "",
      positions: [
        { top: { x: 215, y: 647 }, bottom: { x: 278, y: 786 } },
        { top: { x: 362, y: 315 }, bottom: { x: 420, y: 438 } },
        { top: { x: 1080, y: 149 }, bottom: { x: 1141, y: 286 } },
        { top: { x: 1026, y: 692 }, bottom: { x: 1098, y: 838 } },
        { top: { x: 1514, y: 290 }, bottom: { x: 1555, y: 440 } },
        { top: { x: 1335, y: 518 }, bottom: { x: 1397, y: 643 } },
      ],
    },
    {
      quest: "quest2",
      description: "Find Negamoon Sisters",
      hint: "",
      positions: [{ top: { x: 1528, y: 965 }, bottom: { x: 1667, y: 1055 } }],
    },
    {
      quest: "quest3",
      description: "Find Jedite",
      hint: "",
      positions: [{ top: { x: 557, y: 305 }, bottom: { x: 598, y: 430 } }],
    },
    {
      quest: "quest4",
      description: "Find Wiseman",
      hint: "",
      positions: [{ top: { x: 997, y: 924 }, bottom: { x: 1065, y: 1010 } }],
    },
    {
      quest: "quest5",
      description: "Find Naru",
      hint: "",
      positions: [{ top: { x: 600, y: 938 }, bottom: { x: 641, y: 1063 } }],
    },
    {
      quest: "quest6",
      description: "Find Black Lady",
      hint: "",
      positions: [{ top: { x: 940, y: 930 }, bottom: { x: 1014, y: 1061 } }],
    },
    {
      quest: "quest7",
      description: "Find Rubeus",
      hint: "",
      positions: [{ top: { x: 741, y: 1049 }, bottom: { x: 829, y: 1165 } }],
    },
    {
      quest: "quest8",
      description: "Find Queen Beryl",
      hint: "",
      positions: [{ top: { x: 856, y: 239 }, bottom: { x: 938, y: 395 } }],
    },
    {
      quest: "quest9",
      description: "Find Queen Nehelenia",
      hint: "",
      positions: [{ top: { x: 1672, y: 805 }, bottom: { x: 1719, y: 934 } }],
    },
    {
      quest: "quest10",
      description: "Find Alan, Ann",
      hint: "",
      positions: [{ top: { x: 1208, y: 645 }, bottom: { x: 1274, y: 825 } }],
    },
    {
      quest: "quest11",
      description: "Find Sailor Galaxia",
      hint: "",
      positions: [{ top: { x: 491, y: 450 }, bottom: { x: 545, y: 565 } }],
    },
    {
      quest: "quest12",
      description: "Find Helios",
      hint: "",
      positions: [{ top: { x: 1428, y: 1045 }, bottom: { x: 1622, y: 1247 } }],
    },
    {
      quest: "quest13",
      description: "Find Princess Kakyuu",
      hint: "",
      positions: [{ top: { x: 692, y: 180 }, bottom: { x: 747, y: 309 } }],
    },
    {
      quest: "quest14",
      description: "Find Motoki",
      hint: "",
      positions: [{ top: { x: 1145, y: 192 }, bottom: { x: 1196, y: 315 } }],
    },
    {
      quest: "quest15",
      description: "Find Rhett Butler",
      hint: "",
      positions: [{ top: { x: 854, y: 444 }, bottom: { x: 901, y: 493 } }],
    },
  ],
  translationSpace: "sailormoon",
  name: "SAILOR MOON",
  level: 12,
  catalogOrder: 15,
  updateDate: new Date(),
};

export const level13Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Schierke",
      hint: "",
      positions: [{ top: { x: 1514, y: 246 }, bottom: { x: 1673, y: 395 } }],
    },
    {
      quest: "quest2",
      description: "Find Pippin",
      hint: "",
      positions: [{ top: { x: 1400, y: 952 }, bottom: { x: 1537, y: 1151 } }],
    },
    {
      quest: "quest3",
      description: "Find Sonia",
      hint: "",
      positions: [{ top: { x: 337, y: 823 }, bottom: { x: 415, y: 1022 } }],
    },
    {
      quest: "quest4",
      description: "Find Demon horse",
      hint: "",
      positions: [{ top: { x: 597, y: 1100 }, bottom: { x: 692, y: 1259 } }],
    },
    {
      quest: "quest5",
      description: "Find Farnèse",
      hint: "",
      positions: [{ top: { x: 1645, y: 849 }, bottom: { x: 1763, y: 1059 } }],
    },
    {
      quest: "quest6",
      description: "Find Casca",
      hint: "",
      positions: [{ top: { x: 1410, y: 818 }, bottom: { x: 1479, y: 929 } }],
    },
    {
      quest: "quest7",
      description: "Find Mozgus",
      hint: "",
      positions: [{ top: { x: 68, y: 294 }, bottom: { x: 249, y: 526 } }],
    },
    {
      quest: "quest8",
      description: "Find Charlotte",
      hint: "",
      positions: [{ top: { x: 637, y: 740 }, bottom: { x: 692, y: 899 } }],
    },
    {
      quest: "quest9",
      description: "Find Schnoz",
      hint: "",
      positions: [{ top: { x: 1045, y: 1091 }, bottom: { x: 1106, y: 1148 } }],
    },
    {
      quest: "quest10",
      description: "Find Isidro",
      hint: "",
      positions: [{ top: { x: 1345, y: 390 }, bottom: { x: 1476, y: 506 } }],
    },
    {
      quest: "quest11",
      description: "Find Serpico",
      hint: "",
      positions: [{ top: { x: 1657, y: 677 }, bottom: { x: 1788, y: 897 } }],
    },
  ],
  translationSpace: "berserk",
  name: "BERSERK",
  level: 13,
  catalogOrder: 2,
  updateDate: new Date(),
};

export const level14Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Pod 042",
      hint: "",
      positions: [{ top: { x: 530, y: 437 }, bottom: { x: 582, y: 528 } }],
    },
    {
      quest: "quest2",
      description: "Find Eve",
      hint: "",
      positions: [{ top: { x: 1039, y: 227 }, bottom: { x: 1079, y: 261 } }],
    },
    {
      quest: "quest3",
      description: "Find Simone",
      hint: "",
      positions: [{ top: { x: 1083, y: 755 }, bottom: { x: 1172, y: 888 } }],
    },
    {
      quest: "quest4",
      description: "Find Girl in Red",
      hint: "",
      positions: [{ top: { x: 427, y: 340 }, bottom: { x: 479, y: 446 } }],
    },
    {
      quest: "quest5",
      description: "Find Seere",
      hint: "",
      positions: [{ top: { x: 1222, y: 177 }, bottom: { x: 1249, y: 269 } }],
    },
    {
      quest: "quest6",
      description: "Find Weiss",
      hint: "",
      positions: [{ top: { x: 1516, y: 693 }, bottom: { x: 1597, y: 753 } }],
    },
    {
      quest: "quest7",
      description: "Find Android A2",
      hint: "",
      positions: [{ top: { x: 1412, y: 313 }, bottom: { x: 1525, y: 474 } }],
    },
    {
      quest: "quest8",
      description: "Find Android 2B",
      hint: "",
      positions: [{ top: { x: 711, y: 496 }, bottom: { x: 871, y: 674 } }],
    },
    {
      quest: "quest9",
      description: "Find Kaine",
      hint: "",
      positions: [{ top: { x: 1597, y: 708 }, bottom: { x: 1666, y: 859 } }],
    },
    {
      quest: "quest10",
      description: "Find Pascal",
      hint: "",
      positions: [{ top: { x: 101, y: 913 }, bottom: { x: 204, y: 1009 } }],
    },
    {
      quest: "quest11",
      description: "Find Emil",
      hint: "",
      positions: [
        { top: { x: 1486, y: 792 }, bottom: { x: 1540, y: 901 } },
        { top: { x: 1079, y: 562 }, bottom: { x: 1158, y: 1039 } },
        { top: { x: 1651, y: 1283 }, bottom: { x: 1728, y: 1345 } },
      ],
    },
  ],
  translationSpace: "nier",
  name: "NIER",
  level: 14,
  catalogOrder: 13,
  updateDate: new Date(),
};

export const level15Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Tanjiro",
      hint: "",
      positions: [{ top: { x: 788, y: 333 }, bottom: { x: 937, y: 481 } }],
    },
    {
      quest: "quest2",
      description: "Find Nezuko",
      hint: "",
      positions: [{ top: { x: 461, y: 483 }, bottom: { x: 687, y: 629 } }],
    },
    {
      quest: "quest3",
      description: "Find Inosuke",
      hint: "",
      positions: [{ top: { x: 1196, y: 443 }, bottom: { x: 1335, y: 656 } }],
    },
    {
      quest: "quest4",
      description: "Find Muzan",
      hint: "",
      positions: [{ top: { x: 834, y: 1012 }, bottom: { x: 924, y: 1252 } }],
    },
    {
      quest: "quest5",
      description: "Find Zenitsu",
      hint: "",
      positions: [{ top: { x: 1181, y: 806 }, bottom: { x: 1317, y: 981 } }],
    },
    {
      quest: "quest6",
      description: "Find Tomioka",
      hint: "",
      positions: [{ top: { x: 796, y: 745 }, bottom: { x: 886, y: 856 } }],
    },
    {
      quest: "quest7",
      description: "Find Genya",
      hint: "",
      positions: [{ top: { x: 1395, y: 375 }, bottom: { x: 1460, y: 486 } }],
    },
    {
      quest: "quest8",
      description: "Find Kanao",
      hint: "",
      positions: [{ top: { x: 1210, y: 147 }, bottom: { x: 1304, y: 292 } }],
    },
    {
      quest: "quest9",
      description: "Find Rengoku",
      hint: "",
      positions: [{ top: { x: 1007, y: 668 }, bottom: { x: 1077, y: 792 } }],
    },
    {
      quest: "quest10",
      description: "Find Kanroji",
      hint: "",
      positions: [{ top: { x: 264, y: 376 }, bottom: { x: 392, y: 509 } }],
    },
    {
      quest: "quest11",
      description: "Find Rui",
      hint: "",
      positions: [{ top: { x: 1584, y: 892 }, bottom: { x: 1626, y: 1040 } }],
    },
    {
      quest: "quest12",
      description: "Find Daki",
      hint: "",
      positions: [{ top: { x: 67, y: 881 }, bottom: { x: 198, y: 979 } }],
    },
  ],
  translationSpace: "demonslayer",
  name: "DEMON SLAYER",
  level: 15,
  catalogOrder: 12,
  updateDate: new Date(),
};

export const level16Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Gon",
      hint: "",
      positions: [{ top: { x: 748, y: 212 }, bottom: { x: 871, y: 440 } }],
    },
    {
      quest: "quest2",
      description: "Find Netero",
      hint: "",
      positions: [{ top: { x: 394, y: 929 }, bottom: { x: 607, y: 1262 } }],
    },
    {
      quest: "quest3",
      description: "Find Biscuit",
      hint: "",
      positions: [{ top: { x: 687, y: 730 }, bottom: { x: 853, y: 870 } }],
    },
    {
      quest: "quest4",
      description: "Find Nanika",
      hint: "",
      positions: [{ top: { x: 1287, y: 585 }, bottom: { x: 1506, y: 744 } }],
    },
    {
      quest: "quest5",
      description: "Find Morel",
      hint: "",
      positions: [{ top: { x: 517, y: 901 }, bottom: { x: 649, y: 1170 } }],
    },
    {
      quest: "quest6",
      description: "Find Komugi",
      hint: "",
      positions: [{ top: { x: 131, y: 1142 }, bottom: { x: 240, y: 1237 } }],
    },
    {
      quest: "quest7",
      description: "Find Knuckle",
      hint: "",
      positions: [{ top: { x: 624, y: 610 }, bottom: { x: 716, y: 761 } }],
    },
    {
      quest: "quest8",
      description: "Find Chrollo",
      hint: "",
      positions: [{ top: { x: 1047, y: 834 }, bottom: { x: 1156, y: 1053 } }],
    },
    {
      quest: "quest9",
      description: "Find Kurapika",
      hint: "",
      positions: [{ top: { x: 310, y: 515 }, bottom: { x: 428, y: 739 } }],
    },
    {
      quest: "quest10",
      description: "Find Leolio",
      hint: "",
      positions: [{ top: { x: 1246, y: 201 }, bottom: { x: 1415, y: 387 } }],
    },
    {
      quest: "quest11",
      description: "Find Genthru",
      hint: "",
      positions: [{ top: { x: 1066, y: 155 }, bottom: { x: 1256, y: 310 } }],
    },
    {
      quest: "quest12",
      description: "Find Togashi",
      hint: "",
      positions: [{ top: { x: 1486, y: 673 }, bottom: { x: 1532, y: 709 } }],
    },
  ],
  translationSpace: "hunter",
  name: "HUNTER x HUNTER",
  level: 16,
  catalogOrder: 12,
  updateDate: new Date(),
};

export const level17Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Star Platinum",
      hint: "",
      positions: [{ top: { x: 924, y: 488 }, bottom: { x: 1050, y: 600 } }],
    },
    {
      quest: "quest2",
      description: "Find The World",
      hint: "",
      positions: [{ top: { x: 803, y: 546 }, bottom: { x: 954, y: 667 } }],
    },
    {
      quest: "quest3",
      description: "Find Killer Queen",
      hint: "",
      positions: [{ top: { x: 594, y: 247 }, bottom: { x: 695, y: 362 } }],
    },
    {
      quest: "quest4",
      description: "Find King Crimson",
      hint: "",
      positions: [{ top: { x: 1020, y: 967 }, bottom: { x: 1136, y: 1118 } }],
    },
    {
      quest: "quest5",
      description: "Find Made in Heaven",
      hint: "",
      positions: [{ top: { x: 1433, y: 702 }, bottom: { x: 1506, y: 886 } }],
    },
    {
      quest: "quest6",
      description: "Find Spice Girl",
      hint: "",
      positions: [{ top: { x: 546, y: 702 }, bottom: { x: 599, y: 755 } }],
    },
    {
      quest: "quest7",
      description: "Find Bruno Buccellati",
      hint: "",
      positions: [{ top: { x: 781, y: 1133 }, bottom: { x: 866, y: 1234 } }],
    },
    {
      quest: "quest8",
      description: "Find D4C",
      hint: "",
      positions: [{ top: { x: 229, y: 934 }, bottom: { x: 307, y: 1101 } }],
    },
    {
      quest: "quest9",
      description: "Find Joseph Joestar",
      hint: "",
      positions: [{ top: { x: 262, y: 609 }, bottom: { x: 332, y: 765 } }],
    },
    {
      quest: "quest10",
      description: "Find Johnny Joestar",
      hint: "",
      positions: [{ top: { x: 377, y: 839 }, bottom: { x: 448, y: 985 } }],
    },
    {
      quest: "quest11",
      description: "Find Rohan",
      hint: "",
      positions: [{ top: { x: 312, y: 566 }, bottom: { x: 375, y: 690 } }],
    },
    {
      quest: "quest12",
      description: "Find Jonathan Joestar",
      hint: "",
      positions: [{ top: { x: 456, y: 282 }, bottom: { x: 521, y: 375 } }],
    },
  ],
  translationSpace: "jojo",
  name: "JOJO",
  level: 17,
  catalogOrder: 6,
  updateDate: new Date(),
};

export const level18Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Shiho",
      hint: "",
      positions: [{ top: { x: 176, y: 967 }, bottom: { x: 234, y: 1096 } }],
    },
    {
      quest: "quest2",
      description: "Find Mara",
      hint: "",
      positions: [{ top: { x: 1239, y: 1191 }, bottom: { x: 1337, y: 1302 } }],
    },
    {
      quest: "quest3",
      description: "Find Kawakami",
      hint: "",
      positions: [{ top: { x: 108, y: 907 }, bottom: { x: 173, y: 1035 } }],
    },
    {
      quest: "quest4",
      description: "Find Futaba",
      hint: "",
      positions: [{ top: { x: 1060, y: 214 }, bottom: { x: 1191, y: 307 } }],
    },
    {
      quest: "quest5",
      description: "Find Buchimaru",
      hint: "",
      positions: [{ top: { x: 760, y: 972 }, bottom: { x: 798, y: 1025 } }],
    },
    {
      quest: "quest6",
      description: "Find Jack Frost",
      hint: "",
      positions: [{ top: { x: 675, y: 524 }, bottom: { x: 720, y: 597 } }],
    },
    {
      quest: "quest7",
      description: "Find Alice",
      hint: "",
      positions: [{ top: { x: 425, y: 758 }, bottom: { x: 476, y: 864 } }],
    },
    {
      quest: "quest8",
      description: "Find Calling card",
      hint: "",
      positions: [{ top: { x: 1731, y: 831 }, bottom: { x: 1819, y: 942 } }],
    },
    {
      quest: "quest9",
      description: "Find Lala-chan",
      hint: "",
      positions: [{ top: { x: 448, y: 1073 }, bottom: { x: 514, y: 1184 } }],
    },
    {
      quest: "quest10",
      description: "Find Makoto",
      hint: "",
      positions: [{ top: { x: 1035, y: 607 }, bottom: { x: 1116, y: 723 } }],
    },
    {
      quest: "quest11",
      description: "Find Igor",
      hint: "",
      positions: [{ top: { x: 939, y: 1224 }, bottom: { x: 995, y: 1302 } }],
    },
    {
      quest: "quest12",
      description: "Find Sae",
      hint: "",
      positions: [
        { top: { x: 277, y: 768 }, bottom: { x: 327, y: 909 } },
        { top: { x: 1385, y: 640 }, bottom: { x: 1496, y: 783 } },
      ],
    },
  ],
  translationSpace: "persona",
  name: "PERSONA 5 ROYAL",
  level: 18,
  catalogOrder: 9,
  updateDate: new Date(),
};

export const level19Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Kamina",
      hint: "",
      positions: [{ top: { x: 1073, y: 826 }, bottom: { x: 1166, y: 1002 } }],
    },
    {
      quest: "quest2",
      description: "Find Ebichu",
      hint: "",
      positions: [{ top: { x: 796, y: 1053 }, bottom: { x: 816, y: 1096 } }],
    },
    {
      quest: "quest3",
      description: "Find King Kitan",
      hint: "",
      positions: [{ top: { x: 1723, y: 279 }, bottom: { x: 1844, y: 451 } }],
    },
    {
      quest: "quest4",
      description: "Find Naota",
      hint: "",
      positions: [{ top: { x: 710, y: 461 }, bottom: { x: 834, y: 589 } }],
    },
    {
      quest: "quest5",
      description: "Find Lucy",
      hint: "",
      positions: [{ top: { x: 1520, y: 340 }, bottom: { x: 1584, y: 480 } }],
    },
    {
      quest: "quest6",
      description: "Find King",
      hint: "",
      positions: [{ top: { x: 1468, y: 1058 }, bottom: { x: 1534, y: 1121 } }],
    },
    {
      quest: "quest7",
      description: "Find Mako",
      hint: "",
      positions: [{ top: { x: 1713, y: 879 }, bottom: { x: 1758, y: 1015 } }],
    },
    {
      quest: "quest8",
      description: "Find Captain Nemo",
      hint: "",
      positions: [{ top: { x: 1214, y: 874 }, bottom: { x: 1274, y: 1098 } }],
    },
    {
      quest: "quest9",
      description: "Find Akko",
      hint: "",
      positions: [{ top: { x: 312, y: 687 }, bottom: { x: 408, y: 864 } }],
    },
    {
      quest: "quest10",
      description: "Find Asuka",
      hint: "",
      positions: [{ top: { x: 113, y: 1106 }, bottom: { x: 317, y: 1164 } }],
    },
    {
      quest: "quest11",
      description: "Find Kanti",
      hint: "",
      positions: [{ top: { x: 1547, y: 783 }, bottom: { x: 1650, y: 1028 } }],
    },
    {
      quest: "quest12",
      description: "Find Yoko",
      hint: "",
      positions: [{ top: { x: 1242, y: 403 }, bottom: { x: 1297, y: 544 } }],
    },
  ],
  translationSpace: "gainax",
  name: "GAINAX TRIGGER",
  level: 19,
  catalogOrder: 8,
  updateDate: new Date(),
};

export const level20Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Eren",
      hint: "",
      positions: [{ top: { x: 1007, y: 597 }, bottom: { x: 1166, y: 788 } }],
    },
    {
      quest: "quest2",
      description: "Find Mikasa",
      hint: "",
      positions: [{ top: { x: 1229, y: 60 }, bottom: { x: 1451, y: 413 } }],
    },
    {
      quest: "quest3",
      description: "Find Levi",
      hint: "",
      positions: [{ top: { x: 642, y: 254 }, bottom: { x: 740, y: 370 } }],
    },
    {
      quest: "quest4",
      description: "Find War Hammer Titan ",
      hint: "",
      positions: [{ top: { x: 1509, y: 499 }, bottom: { x: 1562, y: 675 } }],
    },
    {
      quest: "quest5",
      description: "Find Armored Titan",
      hint: "",
      positions: [{ top: { x: 539, y: 589 }, bottom: { x: 687, y: 765 } }],
    },
    {
      quest: "quest6",
      description: "Find Jaw Titan",
      hint: "",
      positions: [{ top: { x: 1647, y: 677 }, bottom: { x: 1741, y: 760 } }],
    },
    {
      quest: "quest7",
      description: "Find Historia",
      hint: "",
      positions: [{ top: { x: 927, y: 1096 }, bottom: { x: 997, y: 1252 } }],
    },
    {
      quest: "quest8",
      description: "Find Annie",
      hint: "",
      positions: [{ top: { x: 1597, y: 861 }, bottom: { x: 1660, y: 1043 } }],
    },
    {
      quest: "quest9",
      description: "Find Hange",
      hint: "",
      positions: [{ top: { x: 1040, y: 113 }, bottom: { x: 1111, y: 259 } }],
    },
    {
      quest: "quest10",
      description: "Find Beast Titan",
      hint: "",
      positions: [{ top: { x: 307, y: 314 }, bottom: { x: 405, y: 466 } }],
    },
    {
      quest: "quest11",
      description: "Find Cart Titan",
      hint: "",
      positions: [{ top: { x: 148, y: 375 }, bottom: { x: 257, y: 473 } }],
    },
  ],
  translationSpace: "snk",
  name: "ATTACK ON TITAN",
  level: 20,
  catalogOrder: 6,
  updateDate: new Date(),
};

export const level21Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Clow Reed",
      hint: "",
      positions: [{ top: { x: 1500, y: 834 }, bottom: { x: 1605, y: 1065 } }],
    },
    {
      quest: "quest2",
      description: "Find Mokona",
      hint: "",
      positions: [{ top: { x: 1272, y: 1146 }, bottom: { x: 1350, y: 1209 } }],
    },
    {
      quest: "quest3",
      description: "Find Kamui",
      hint: "",
      positions: [{ top: { x: 1267, y: 400 }, bottom: { x: 1342, y: 670 } }],
    },
    {
      quest: "quest4",
      description: "Find Kobato",
      hint: "",
      positions: [{ top: { x: 1141, y: 870 }, bottom: { x: 1200, y: 1118 } }],
    },
    {
      quest: "quest5",
      description: "Find Shaolan Li",
      hint: "",
      positions: [{ top: { x: 219, y: 206 }, bottom: { x: 337, y: 370 } }],
    },
    {
      quest: "quest6",
      description: "Find Chii",
      hint: "",
      positions: [{ top: { x: 362, y: 894 }, bottom: { x: 501, y: 1123 } }],
    },
    {
      quest: "quest7",
      description: "Find Yuzuriha",
      hint: "",
      positions: [{ top: { x: 362, y: 304 }, bottom: { x: 428, y: 531 } }],
    },
    {
      quest: "quest8",
      description: "Find Tomoyo",
      hint: "",
      positions: [{ top: { x: 1436, y: 25 }, bottom: { x: 1554, y: 244 } }],
    },
    {
      quest: "quest9",
      description: "Find Suh",
      hint: "",
      positions: [{ top: { x: 972, y: 899 }, bottom: { x: 1030, y: 1164 } }],
    },
    {
      quest: "quest10",
      description: "Find Ioryogi",
      hint: "",
      positions: [{ top: { x: 1355, y: 990 }, bottom: { x: 1451, y: 1080 } }],
    },
    {
      quest: "quest11",
      description: "Find Sakura",
      hint: "",
      positions: [{ top: { x: 697, y: 539 }, bottom: { x: 924, y: 763 } }],
    },
  ],
  translationSpace: "clamp",
  name: "CLAMP",
  level: 21,
  catalogOrder: 15,
  updateDate: new Date(),
};

export const level22Data = {
  quests: [
    {
      quest: "quest1",
      description: "Find Alpha",
      hint: "",
      positions: [
        { top: { x: 932, y: 150 }, bottom: { x: 989, y: 280 } },
        { top: { x: 702, y: 186 }, bottom: { x: 754, y: 282 } },
      ],
    },
    {
      quest: "quest2",
      description: "Find Zanza",
      hint: "",
      positions: [{ top: { x: 737, y: 495 }, bottom: { x: 806, y: 595 } }],
    },
    {
      quest: "quest3",
      description: "Find Klaus",
      hint: "",
      positions: [{ top: { x: 1164, y: 289 }, bottom: { x: 1202, y: 326 } }],
    },
    {
      quest: "quest4",
      description: "Find Weltall-Id",
      hint: "",
      positions: [{ top: { x: 832, y: 669 }, bottom: { x: 941, y: 780 } }],
    },
    {
      quest: "quest5",
      description: "Find Shulk",
      hint: "",
      positions: [
        { top: { x: 1367, y: 600 }, bottom: { x: 1408, y: 729 } },
        { top: { x: 1270, y: 306 }, bottom: { x: 1336, y: 381 } },
      ],
    },
    {
      quest: "quest6",
      description: "Find Rex",
      hint: "",
      positions: [
        { top: { x: 604, y: 1031 }, bottom: { x: 660, y: 1143 } },
        { top: { x: 465, y: 273 }, bottom: { x: 521, y: 329 } },
      ],
    },
    {
      quest: "quest7",
      description: "Find Noah",
      hint: "",
      positions: [{ top: { x: 337, y: 577 }, bottom: { x: 393, y: 671 } }],
    },
    {
      quest: "quest8",
      description: "Find Fiora",
      hint: "",
      positions: [{ top: { x: 1249, y: 666 }, bottom: { x: 1336, y: 736 } }],
    },
    {
      quest: "quest9",
      description: "Find Matthew",
      hint: "",
      positions: [{ top: { x: 1227, y: 94 }, bottom: { x: 1273, y: 200 } }],
    },
    {
      quest: "quest10",
      description: "Find Melia",
      hint: "",
      positions: [{ top: { x: 1357, y: 790 }, bottom: { x: 1488, y: 876 } }],
    },
    {
      quest: "quest11",
      description: "Find Kosmos",
      hint: "",
      positions: [{ top: { x: 1717, y: 239 }, bottom: { x: 1763, y: 308 } }],
    },
    {
      quest: "quest12",
      description: "Find Face Nemesis",
      hint: "",
      positions: [{ top: { x: 1471, y: 652 }, bottom: { x: 1625, y: 800 } }],
    },
    {
      quest: "quest13",
      description: "Find Dunban",
      hint: "",
      positions: [{ top: { x: 1683, y: 736 }, bottom: { x: 1768, y: 860 } }],
    },
    {
      quest: "quest14",
      description: "Find Mythra",
      hint: "",
      positions: [{ top: { x: 1460, y: 200 }, bottom: { x: 1560, y: 300 } }],
    },
    {
      quest: "quest15",
      description: "Find Shania",
      hint: "",
      positions: [{ top: { x: 200, y: 930 }, bottom: { x: 260, y: 1080 } }],
    },
    {
      quest: "quest16",
      description: "Find Eunie",
      hint: "",
      positions: [{ top: { x: 50, y: 330 }, bottom: { x: 330, y: 475 } }],
    },
  ],
  translationSpace: "xenoblade",
  name: "XENOBLADE",
  level: 22,
  catalogOrder: 22,
  updateDate: new Date(),
};
